<template>
  <b-container
      class="bv-example-row mb-3"
      fluid
  >
    <hr>
    <b-card
        id="printMe"
        class="mb-4"
    >
      <b-row class="mt-50">
        <b-col lg="6" md="12" sm="12" cols="12">
          <h3 class="ml-1 ekstreTitle">
            {{ 'Cari Ekstre ' + cardTitle }}
          </h3>
        </b-col>
        <b-col
            class="justify-content-end buttonRow"
            lg="6" md="12" sm="12" cols="12"
            style="justify-content: end; display: flex"
            :style="{justifyContent : width < 1000 ? 'center!important':'end'}"
        >
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="bgInfo"
              class="myButton mr-1"
              @click="$router.go(-1)"
          >
            <feather-icon
                icon="ChevronLeftIcon"
                class="mr-50"
                v-if="width > 1200"
            />
            Geri Dön
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-print="'#printMe'"
              variant="bgInfo"
              class="myButton mr-1"
              :style="{backgroundColor:$store.getters.bgInfo}"
          >
            <feather-icon
                icon="PrinterIcon"
                class="mr-50"
                v-if="width > 1200"
            />
            Yazdır
          </b-button>
        </b-col>
      </b-row>
      <hr>
      <table class="table table-striped mt-2 table-responsive-md">
        <thead>
        <tr>
          <th scope="col" :style="{backgroundColor:$store.getters.cardTitle,color:'white'}">
            İŞLEM TÜRÜ
          </th>
          <th scope="col" :style="{backgroundColor:$store.getters.cardTitle,color:'white'}">
            AÇIKLAMA
          </th>
          <th scope="col" :style="{backgroundColor:$store.getters.cardTitle,color:'white'}">
            İŞLEM TARİHİ
          </th>
          <th scope="col" :style="{backgroundColor:$store.getters.cardTitle,color:'white'}">
            BORÇ
          </th>
          <th scope="col" :style="{backgroundColor:$store.getters.cardTitle,color:'white'}">
            ALACAK
          </th>
          <th scope="col" :style="{backgroundColor:$store.getters.cardTitle,color:'white'}">
            BAKİYE
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(item, index) in ekstreList"
            :key="index"
        >
          <td class="font-weight-bold">
            {{ item.islemTuru }}
          </td>
          <td>{{ item.islemNotu }}</td>
          <td>{{ item.kayitTarih }}</td>
          <td>{{ item.borc }}</td>
          <td>{{ item.alacak }}</td>
          <td>
            {{ item.bakiye }} TL <br>
            <b-badge variant="light-primary">
              {{ item.bakiye == 0 ? 'Bakiye Yok' : item.bakiye > 0 ? 'Tahsil Edilecek' : 'Ödenecek' }}
            </b-badge>
          </td>
        </tr>
        </tbody>
      </table>
      <hr>
      <b-row>
        <b-col
            cols="12"
            class="d-flex justify-content-end"
        >
          <h3>Bakiye : {{ anaBakiye }} TL</h3>
        </b-col>
        <b-col
            cols="12"
            class="d-flex justify-content-end"
        >
          <b-badge variant="light-primary">
            {{ anaBakiye == 0 ? 'Bakiye Yok' : anaBakiye > 0 ? 'Tahsil Edilecek' : 'Ödenecek' }}
          </b-badge>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import {
  BListGroup, BListGroupItem,
  BMedia, BMediaAside,
  BMediaBody,
  BFormTextarea,
  BRow,
  BCol,
  BContainer,
  BTable,
  BBadge,
  BForm,
  BButton,
  BCard,
  BBreadcrumb,
  BButtonGroup,
  BFormInput,
  BInputGroup,
  BSpinner,
  BAvatar,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroupAppend,
  BFormCheckboxGroup,
  BFormCheckbox,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import _ from 'lodash'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useWindowSize } from '@vueuse/core'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BFormTextarea,
    vSelect,
    BMedia,
    BMediaAside,
    BMediaBody,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BContainer,
    BTable,
    BBadge,
    BForm,
    BButton,
    BCard,
    BBreadcrumb,
    BButtonGroup,
    BFormInput,
    BInputGroup,
    BSpinner,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroupAppend,
    BFormCheckboxGroup,
    BFormCheckbox,
    BModal,
  },
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      id: this.$route.params.id,
    }
  },
  computed: {
    salaryListItems() {
      return this.$store.getters.cariEkstreReturn
    },
    cardTitle() {
      if (this.$store.getters.cariEkstreReturn.cariBilgi) {
        return `${this.$store.getters.cariEkstreReturn.cariBilgi.faturaUnvan} (${this.$store.getters.cariEkstreReturn.cariBilgi.adi} ${this.$store.getters.cariEkstreReturn.cariBilgi.soyAdi})`
      }
    },
    ekstreList() {
      return Object.values(_.orderBy(this.$store.getters.cariEkstreReturn.ekstre, 'kayitTarih', 'desc'))
    },
    anaBakiye() {
      if (this.$store.getters.cariEkstreReturn.cariOzet) {
        return this.$store.getters.cariEkstreReturn.cariOzet.bakiye
      }
    },
  },
  created() {
    this.$store.dispatch('cariEkstre', {
      id: this.id,
    })
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
          }
        })
        .catch(err => {
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  beforeDestroy() {
    this.$store.commit('clearCariEkstre')
  },
  methods: {
  },
}
</script>

<style scoped>
[dir] .badge[data-v-f95f1cd4] {
  padding: 0.3rem 0.4rem;
}

[dir] .badge {
  padding: 0.5rem 0.5rem;
}

[dir] h4 {
  margin-bottom: 0.1rem;
}

[dir] .list-group-item {
  padding: 0.45rem 0.25rem;
}

.table {
  text-align: center;
}

.spinners [dir] .badge[data-v-f95f1cd4] {
  padding: 0.3rem 0.4rem;
}

[dir] .badge {
  padding: 0.5rem 0.5rem;
}

[dir] h4 {
  margin-bottom: 0.1rem;
}

[dir] .list-group-item {
  padding: 0.45rem 0.25rem;
}

.table {
  text-align: center;
}

.spinners {
  position: absolute;
  margin-left: 47%;
  margin-top: 15%;
  width: 3rem;
  height: 3rem;
}

.mr-12 {
  margin-right: 12px !important;
  background-color: #2e83c9 !important;
}

.myHr {
  width: 60% !important;
}


[dir] .badge-primary {
  background-color: rgba(115, 103, 240, 0.12);
  color: #7367f0 !important;
}

.spinners {
  position: absolute;
  margin-left: 47%;
  margin-top: 15%;
  width: 3rem;
  height: 3rem;
}

.myButton {
  color: white !important;
}

.myButton:hover {
  color: white !important;
}

a {
  color: #408dcc !important;
}

a:hover {
  color: #6d62e4 !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.row {
  justify-content: space-between;
}

.content-wrapper {

}

.table td {
  padding: 0.1rem !important;
}

.col-6 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
